import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Relatorio } from '../../shared/util/relatorio.model';
import { Resultado } from '../../shared/util/resultado.model';
import { Pedido } from './pedido.model';


@Injectable()
export class PedidoService extends VsCrudService<Pedido> {

    public url: string = "api/pedidos";

    public url_NFE: string = "http://192.168.0.99:8080/integracao/gravar/26";
    pageResult: PageResult = new PageResult();
    private httpNFE: HttpClient;

    constructor(_http: HttpClient, _message: MessageService) {
        super(<any>_http, _message);
        this.httpNFE = _http;
    }

    public list(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/search', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    public listPersonalizado(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchPersonalizado', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    public listPersonalizadoCancelamento(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchPersonalizadoCancelamento', search)
            .subscribe(res => {
                console.log(res)
                console.log(res)
                console.log(res)

                self.pageResult = res;
            });
    }
    public imprimirNFE(id: Number, idLoja: Number) {
        let resultado: any;
        return this.get(`${this.url}/imprimirNFE/${id}/${idLoja}`).toPromise().then(res =>
            <Relatorio>res);
       }

       
     public imprimirNFEById(id: Number) {
        let resultado: any;
        return this.get(`${this.url}/imprimirNFE/${id}`).toPromise().then(res =>
            <Relatorio>res);
       }

    // public imprimirPedido(id: Number, idLoja:number) {
    //     let resultado: any;
    //     return this.get(`${this.url}/imprimir/${id}/${idLoja}`).toPromise().then(res =>
    //         <Relatorio>res);
    // }

    public imprimirPedido(id: Number, tela: string) {
        let resultado: any;
        return this.get(`${this.url}/imprimir/${id}/${tela}`).toPromise().then(res =>
            <Relatorio>res);
    }

    public imprimirRecibo(id: Number, tela: string) {
        let resultado: any;
        return this.get(`${this.url}/imprimirRecibo/${id}/${tela}`).toPromise().then(res =>
            <Relatorio>res);
    }

    public verificarCertificado(idLoja: Number) {
        let resultado: any;
        return this.get(`${this.url}/certificado/${idLoja}`).toPromise().then(res =>
            <Relatorio>res);
    }

    public imprimirPedidoSeparacao(id: Number,  idLoja:number) {
        let resultado: any;
        return this.get(`${this.url}/imprimirSeparacao/${id}/${idLoja}`).toPromise().then(res =>
            <Relatorio>res);
    }


    public savePedido(param: Object) {
        return this.postPedido(`${this.url}/savePedido`, param);
    }

    public alteraPedido(param: Object) {
        return this.postPedido(`${this.url}/alteraPedido`, param);
    }

    public gravarArquivoNfNovamente(id: Number) {
        let resultado: any;
        return this.get(`${this.url}/gerarArquivoNF/${id}`).toPromise().then(res =>
            <Resultado>res);
    }


    public listVendas(param: Object) {
        return this.postPromisse(`${this.url}/listVendas`, param);
    }

    // public imprimirNFCE(id: Number, idLoja:Number) {
    //   let resultado: any;
    //   return this.get(`${this.url}/imprimirNFCE/${id}/${idLoja}`).toPromise().then(res =>
    //       <Relatorio>res);
    //  }

     public imprimirNFCE(id: Number) {
        let resultado: any;
        return this.get(`${this.url}/imprimirNFCE/${id}`).toPromise().then(res =>
            <Relatorio>res);
       }

}



